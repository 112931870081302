import React, { Component } from "react";

import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import auth from "./services/auth/authService";
import "./scss/style.scss";
import "./custom.css";

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
const Logout = React.lazy(() => import("./components/auth/logout"));
const Register = React.lazy(() => import("./views/pages/register/Register"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));

class App extends Component {
  state = {
    user: null,
    loading: true,
  };

  componentDidMount() {
    const user = auth.getJwt();
    this.setState({ user, loading: false });
  }

  render() {
    const user = auth.getJwt();
    return (
      <BrowserRouter loading>
        {/* <Loader /> */}
        <React.Suspense fallback={false}>
          <Switch>
            <Route
              exact
              path="/login"
              name="Login Page"
              render={(props) => <Login {...props} />}
            />
            <Route exact path="/logout" component={Logout} />
            <Route
              exact
              path="/register"
              name="Register Page"
              render={(props) => <Register {...props} />}
            />
            <Route
              exact
              path="/404"
              name="Page 404"
              render={(props) => <Page404 {...props} />}
            />
            <Route
              exact
              path="/500"
              name="Page 500"
              render={(props) => <Page500 {...props} />}
            />
            <Route
              path="/"
              name="Home"
              render={(props) => {
                if (!user) return <Redirect to="/login" />;
                return <TheLayout {...props} user={this.state.user} />;
              }}
            />
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    );
  }
}

export default App;
