import { authEndpoint } from "../../config.js";
import http from "../http/httpService";
import jwtDecode from "jwt-decode";

const tokenKey = "token";

http.setJwt(getJwt());

export async function login(cid, password) {
  const { data: jwt } = await http.post(authEndpoint + "login", {
    cid: cid,
    password: password,
  });

  if (jwt.access_token === undefined) {
    return jwt;
  } else {
    localStorage.setItem(tokenKey, jwt.access_token);
  }
}

export async function getUserDetail() {
  return await http.get(authEndpoint + "user");
}

export function logout() {
  localStorage.removeItem(tokenKey);
}

export function getCurrentUser() {
  try {
    const jwt = localStorage.getItem(tokenKey);
    return jwtDecode(jwt.access_token);
  } catch (ex) {
    return null;
  }
}

export function getJwt() {
  return localStorage.getItem(tokenKey);
}

export default {
  login,
  logout,
  getCurrentUser,
  getJwt,
  getUserDetail,
};
